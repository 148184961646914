import bio_vector from "../../../assets/vectors/bio_vector.svg";
import pupillage_vector from "../../../assets/vectors/pupillage_vector.svg";
import chamber_vector from "../../../assets/vectors/chamber_info_vector.svg";
import renew_vector from "../../../assets/vectors/renew_vector.svg";
import shop_vector from "../../../assets/vectors/shop_vector.svg";
import conf_vector from "../../../assets/vectors/conf_vector.svg";
import mili_vector from "../../../assets/vectors/military.svg";
import resource_vector from "../../../assets/vectors/resource_vector.svg";
import dues_vector from "../../../assets/vectors/money_vector.svg";
import receipt from "../../../assets/vectors/receipt.svg";
import location from "../../../assets/vectors/location.svg";

const menuSections = [
  {
    title: "Payments",
    color: "pink",
    menu: [
      {
        path: "/home/dues",
        name: "Dues Payment",
        description: "Yet to pay your dues? You can pay your dues right here.",
        btn: "Pay / Check Status",
        icon: dues_vector,
      },
      {
        path: "/home/license",
        name: "Solicitors License",
        description: "Renew & make payment for your Solicitor’s License here.",
        btn: "Pay / Check Status",
        icon: renew_vector,
      },
      {
        path: "/home/chamber",
        name: "Chamber Info",
        description: "Apply, Renew & Edit your Chamber License & info.",
        btn: "Apply / Renew",
        icon: chamber_vector,
      },
      {
        path: "/home/pupilage",
        name: "Pupillage Request",
        description: "",
        btn: "Apply",
        icon: pupillage_vector,
      },
    ],
  },
  {
    title: "Registrations",
    color: "teal",
    menu: [
      {
        path: "/home/conferences",
        name: "Conferences",
        description: "Annual Bar Conference",
        btn: "Join / Register",
        icon: conf_vector,
      },
      {
        path: "/home/bootcamp",
        name: "Military Bootcamp",
        description: "Annual Bootcamp",
        btn: "Join / Register",
        icon: mili_vector,
      },
    ],
  },
  {
    title: "Miscellaneous",
    color: "amber",
    menu: [
      {
        path: "/home/resources",
        name: "Resources",
        description: "Documents & files available for download.",
        btn: "View",
        icon: resource_vector,
      },
      {
        path: "/home",
        name: "Shop",
        description: "Order for all Ghana Bar Products here.",
        btn: "Shop Now",
        icon: shop_vector,
      },
      {
        path: "/home/receipts",
        name: "All Receipts",
        description: "Access all receipts",
        btn: "View",
        icon: receipt,
      },
    ],
  },
  // {
  //   title: "External Links",
  //   color: "green",
  //   menu: [
  //     {
  //       path: "/home",
  //       name: "Electronic Voting",
  //       description: "Vote here",
  //       btn: "Vote",
  //       icon: bio_vector,
  //     },
  //     {
  //       path: "/home",
  //       name: "Lawyer Locator",
  //       description: "",
  //       btn: "Vist Site",
  //       icon: location,
  //     },
  //   ],
  // },
];

export default menuSections;
