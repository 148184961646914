import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Chip,
} from "@material-tailwind/react";
import { Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import DetailItem from "../../../common/shared/DetailItem";
import BootcampReceiptButton from "./BootcampReceiptButton";
import { useNavigate } from "react-router-dom";
import OpenModal from "../../../common/actions/OpenModal";
import InputArea from "../../../common/forms/InputArea";
import {
  bootcampRegistrationAction,
  bootcampUpdateAction,
} from "../../../redux/actions/bootcamp_action";
import SelectOption from "../../../common/forms/SelectOption";
import DatePickerComp from "../../../common/forms/DatePicker";

const sizeType = [
  { id: "sm", name: "sm" },
  { id: "md", name: "md" },
  { id: "lg", name: "lg" },
  { id: "xl", name: "xl" },
  { id: "2xl", name: "2xl" },
  { id: "3xl", name: "3xl" },
  { id: "4xl", name: "4xl" },
  { id: "5xl", name: "5xl" },
];

function BootcampList({ row, userData }) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(row);
  const [selectedDate, setSelectedDate] = useState(
    new Date(userData.date_of_birth)
  );

  const [registerForm, setRegisterForm] = useState({
    dob: "",
    size: "",
  });
  const [needs, setNeeds] = useState(false);
  const [register, setRegister] = useState(false);

  const handleNeeds = () => setNeeds(!needs);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updateFormValue = ({ updateType, value }) => {
    setFormData({ ...formData, [updateType]: value });
  };

  const updateRegisterValue = ({ updateType, value }) => {
    setRegisterForm({ ...registerForm, [updateType]: value });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setRegisterForm({ ...registerForm, dob: date.toISOString() });
  };

  const openRegisterModal = () => {
    setRegisterForm({
      dob: userData.date_of_birth,
      size: "",
    });
    setRegister(!register);
  };

  const handleNeedsForm = async (id) => {
    setLoading(true);

    const sendData = {
      id: id,
      special: formData.special,
      bar_number: userData.bar_number,
    };

    await dispatch(bootcampUpdateAction(sendData)).finally(() => {
      setLoading(false);
      setNeeds(!needs);
      window.location.reload();
    });
  };

  const handleRegisterForm = async () => {
    if (
      !registerForm.size ||
      !registerForm.dob ||
      Date.parse(registerForm.dob) >= Date.now()
    ) {
      return;
    }

    setLoading(true);

    const sendData = {
      size: registerForm.size,
      dob: registerForm.dob,
    };

    await dispatch(bootcampRegistrationAction(sendData)).finally(() => {
      setLoading(false);
      setRegister(!register);
      window.location.reload();
    });
  };

  return (
    row &&
    row.map((data) => (
      <div key={data.id} className="card-wrapper">
        <Typography color="red" variant="h6" className="mb-12">
          {data.access} / 50 Applicant. First 50 applicant to complete payment
          will be allowed to participate in this bootcamp.
        </Typography>
        <Card className="mt-6">
          <CardBody>
            {data.receipt && (
              <div className="flex mb-2">
                <Chip color="green" variant="ghost" value="PAID" />
              </div>
            )}

            <Typography variant="h5" color="black" className="mb-12">
              {data.name}
            </Typography>

            {data.venue && <DetailItem title="Venue" item={data.venue} />}

            <DetailItem title="Date" item={`${data.start} - ${data.end}`} />

            <DetailItem title="Amount" item={`GH¢${data.amount.toFixed(2)}`} />
          </CardBody>
          <CardFooter>
            {data.user == null && (
              <Button onClick={() => openRegisterModal()}>
                Register Bootcamp
              </Button>
            )}

            {data.receipt == null && data.user !== null && (
              <Button
                color="pink"
                onClick={() =>
                  navigate("/home/bootcamp/invoice", {
                    state: { data },
                  })
                }
              >
                Make Payment
              </Button>
            )}

            {data.receipt != null && data.user != null && (
              <div className="flex flex-col gap-4 md:flex-row md:items-center">
                <BootcampReceiptButton data={data.receipt} />
                <Button onClick={handleNeeds} color="purple">
                  Special Needs / Special Foods
                </Button>
              </div>
            )}

            {needs && (
              <OpenModal
                open={needs}
                handler={handleNeeds}
                title="Bootcamp Needs"
                actionTitle="Submit"
                handleSubmit={() => handleNeedsForm(data.user.id)}
                loading={loading}
                children={
                  <>
                    <div className="mb-6">
                      <InputArea
                        defaultValue={data?.user.special}
                        updateType="special"
                        labelTitle="Special Needs"
                        updateFormValue={updateFormValue}
                      />
                    </div>
                  </>
                }
              />
            )}
          </CardFooter>
        </Card>

        {register && (
          <OpenModal
            open={register}
            handler={openRegisterModal}
            title="Bootcamp Registration"
            actionTitle="Submit"
            handleSubmit={handleRegisterForm}
            loading={loading}
            children={
              <>
                <div className="mb-6">
                  <SelectOption
                    labelTtile="Select Shirt Size"
                    defaultValue={registerForm.size}
                    updateFormValue={updateRegisterValue}
                    updateType="size"
                    children={sizeType}
                  />
                </div>
                <DatePickerComp
                  label="Date Of Birth"
                  selectedDate={selectedDate}
                  onSelectDate={handleDateChange}
                  defaultDate={userData.date_of_birth}
                  maxDate={new Date()}
                />
              </>
            }
          />
        )}
      </div>
    ))
  );
}

export default BootcampList;
